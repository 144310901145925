import { getDateFromTimestamp, queryKey } from '@segunosoftware/equinox';
import { useMutation, useQuery } from '@tanstack/react-query';
import { USER } from './query-keys';
import type { Get, Post } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

// TODO - replace any with actual type
export type ReviewSentiment = any;
export type ReviewStatus = any;

export const useReviewSentiment = () => {
	const { post } = useAuthenticatedFetch() as Post<ReviewSentiment, void>;
	const { mutate } = useMutation({
		mutationFn: (payload: ReviewSentiment) => post('/account/review-sentiment', payload)
	});
	return mutate;
};

export const useReviewStatus = () => {
	const { post } = useAuthenticatedFetch() as Post<ReviewStatus, void>;
	const { mutate } = useMutation({
		mutationFn: (payload: ReviewStatus) => post('/account/review-status', payload)
	});
	return mutate;
};

export function useAccountFetch(load = false) {
	const { get } = useAuthenticatedFetch() as Get<any>;
	const {
		data: account,
		isLoading,
		refetch: onLoadAccount
	} = useQuery({
		queryKey: queryKey(USER),
		queryFn: () => get('/auth/whoami').then(hydrateAccount),
		enabled: load
	});
	return { account, isLoading, onLoadAccount };
}

export function useAccount() {
	const { account } = useAccountFetch();

	if (!account) {
		throw new Error('account not loaded'); // the account must be loaded before anyone uses this hook! (ProtectedRoute does this)
	}

	return account;
}

const hydrateAccount = (account: any) => {
	return {
		...account,
		createdAt: getDateFromTimestamp(account.createdAt),
		updatedAt: getDateFromTimestamp(account.updatedAt),
		reviewRespondedAt: getDateFromTimestamp(account.reviewRespondedAt)
	};
};
