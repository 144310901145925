import { LoadingScreen } from '@segunosoftware/equinox';
import { useAccountFetch } from '../hooks/useAccount';
import AccountContainer from './AccountContainer';

const ProtectedRoute = ({ element }) => {
	const { account } = useAccountFetch(true);

	return <AccountContainer>{account ? element : <LoadingScreen />}</AccountContainer>;
};

export default ProtectedRoute;
