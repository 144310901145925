import SentryWrapper from '../components/SentryWrapper';
import { useAccountFetch } from '../hooks/useAccount';

const SentryWrapperContainer = props => {
	const { account } = useAccountFetch();

	return <SentryWrapper account={account} {...props} />;
};

export default SentryWrapperContainer;
