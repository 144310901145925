import { useEffect } from 'react';
import { useAccountFetch } from '../hooks/useAccount';

export default function AccountContainer({ children }) {
	const { account, isLoading, onLoadAccount } = useAccountFetch(true);

	useEffect(() => {
		if (!account && !isLoading) {
			onLoadAccount();
		}
	}, [account, isLoading, onLoadAccount]);

	return children;
}
