import * as Sentry from '@sentry/react';
import { AppProvider, Banner, Layout, Page } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import PropTypes from 'prop-types';
import { Component } from 'react';
import CustomLinkComponent from '../CustomLinkComponent';

class SentryWrapper extends Component {
	static propTypes = {
		account: PropTypes.shape({
			id: PropTypes.number.isRequired,
			shop: PropTypes.string.isRequired,
			ownerEmail: PropTypes.string.isRequired
		})
	};

	static defaultProps = {
		account: null
	};

	state = {
		error: null,
		sentryContextId: null
	};

	componentDidMount() {
		this.checkUserContext();
	}

	componentDidUpdate(prevProps, prevState) {
		this.checkUserContext();
	}

	checkUserContext() {
		const { account } = this.props;
		const { sentryContextId } = this.state;
		if (account && account.id !== sentryContextId) {
			Sentry.setUser({
				id: account.id,
				username: account.shop,
				email: account.ownerEmail
			});
			this.setState({
				sentryContextId: account.id
			});
		}
	}

	componentDidCatch(error, info) {
		this.setState({ error });
		if (import.meta.env.VITE_SENTRY_ENVIRONMENT === 'development') {
			return;
		}
		Object.keys(info).forEach(key => {
			Sentry.getCurrentScope().setExtra(key, info[key]);
		});
		Sentry.captureException(error);
		Sentry.showReportDialog();
	}

	render() {
		const { children } = this.props;
		const { error } = this.state;
		if (error) {
			return (
				<AppProvider i18n={translations} linkComponent={CustomLinkComponent}>
					<Page>
						<Layout sectioned>
							<Banner
								title="Something went wrong"
								tone="critical"
								action={{ content: 'Submit report', onAction: () => Sentry.showReportDialog() }}>
								<p>
									We're sorry, we hit an unexpected error. Our team has been notified, but it may help if you submit a report to help us fix
									the problem. You can also refresh your browser and see if the problem happens again.
								</p>
							</Banner>
						</Layout>
					</Page>
				</AppProvider>
			);
		} else {
			return children;
		}
	}
}

export default Sentry.withProfiler(SentryWrapper);
