import { LoadingScreen, useAppBridgeRouter } from '@segunosoftware/equinox';
import { Provider } from '@shopify/app-bridge-react';
import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import { Suspense, useMemo } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import CustomLinkComponent from './CustomLinkComponent';
import ProtectedRoute from './containers/ProtectedRoute';
import SentryWrapperContainer from './containers/SentryWrapperContainer';
import { useHostParam } from './hooks/useHostParam';
import { External, OAuth, SettingsContainer } from './utils/lazy-load';

const SHOPIFY_APP_KEY = import.meta.env.VITE_SHOPIFY_KEY;

export default function App() {
	const location = useLocation();
	const host = useHostParam() ?? '';
	const router = useAppBridgeRouter();
	const isOAuthPath = location.pathname.startsWith('/oauth/shopify');
	const config = useMemo(
		() => ({
			apiKey: SHOPIFY_APP_KEY,
			host,
			forceRedirect: !isOAuthPath
		}),
		[host, isOAuthPath]
	);
	return (
		<AppProvider key={isOAuthPath.toString()} i18n={translations} linkComponent={CustomLinkComponent}>
			<Provider config={config} router={router}>
				<SentryWrapperContainer>
					<Suspense fallback={<LoadingScreen delay={500} />}>
						<Routes>
							<Route path="/oauth/*" element={<OAuth />} />
							<Route path="/external/*" element={<External />} />
							<Route path="/*" element={<ProtectedRoute element={<SettingsContainer />} />} />
						</Routes>
					</Suspense>
				</SentryWrapperContainer>
			</Provider>
		</AppProvider>
	);
}
